import React from 'react';
import R from 'ramda';
import { HeroQuarterImageContent } from './HeroQuarterImageContent';
import HeroQuarterImageContentContainer from '../containers/HeroQuarterImageContentContainer';

const HeroQuarterImageContentComponent = (props) => (
  <HeroQuarterImageContentContainer
    childComponent={HeroQuarterImageContent}
    {...props}
    customHeroesStoreDataKey={R.prop('customHeroesStoreDataKey', props)}
  />
);

export default HeroQuarterImageContentComponent;
