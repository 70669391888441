import React from 'react';
import PropTypes from 'prop-types';
import Picture from '@cotyorg/ccx-atoms/Picture';
import Heading from '@cotyorg/ccx-atoms/Heading';
import Link from '@cotyorg/ccx-atoms/Link';
import Paragraph from '@cotyorg/ccx-atoms/Paragraph';
import classNames from 'classnames';
import R from 'ramda';

const generateLeftColumnClassNames = ({ leftColumnConfig }) =>
  classNames(leftColumnConfig || 'cell xsmall-16 large-11');

const generateRightColumnClassNames = ({ rightColumnConfig }) =>
  classNames(rightColumnConfig || 'cell xsmall-16 large-5');

export const HeroQuarterImageContent = ({
  contentImage,
  description,
  heading,
  imageSrc,
  imageSrcSet,
  alt,
  links,
  customContent,
  newText,
  columnsConfig,
}) => {
  const leftColumnConfig = R.prop('leftColumn', columnsConfig);
  const rightColumnConfig = R.prop('rightColumn', columnsConfig);
  const leftColumnClassNames = generateLeftColumnClassNames({
    leftColumnConfig,
  });
  const rightColumnClassNames = generateRightColumnClassNames({
    rightColumnConfig,
  });

  return (
    <div className="grid-x align-center hero-quarter-image-content">
      <div className={leftColumnClassNames}>
        <Picture doNotLazyLoad src={imageSrc} srcset={imageSrcSet} alt={alt} />
      </div>

      <div className={rightColumnClassNames}>
        <div className="content-side-container">
          <div className="skew-background" />
          <div className="content-wrapper">
            {newText && <p className="hero-new-label">{newText}</p>}

            {!customContent ? (
              <>
                {contentImage && (
                  <div className="hero-logo">
                    <Picture
                      doNotLazyLoad
                      src={R.prop('imageSrc', contentImage)}
                      srcset={R.prop('imageSrcSet', contentImage)}
                      alt={R.prop('alt', contentImage)}
                    />
                  </div>
                )}

                {heading && (
                  <h3
                    className="heading"
                    dangerouslySetInnerHTML={{ __html: heading }}
                  />
                )}

                {description && (
                  <Paragraph extraClass="hero__text hero-full-block-quarter__text">
                    {description}
                  </Paragraph>
                )}

                {!!R.prop('url', links[0]) && (
                  <div>
                    <Link extraClass="cta-link" link={R.prop('url', links[0])}>
                      {R.prop('text', links[0])}
                    </Link>
                  </div>
                )}
              </>
            ) : (
              customContent()
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

HeroQuarterImageContent.propTypes = {
  contentImage: PropTypes.objectOf(PropTypes.object),
  description: PropTypes.string,
  heading: PropTypes.string,
  imageSrc: PropTypes.string,
  imageSrcSet: PropTypes.objectOf(PropTypes.array),
  alt: PropTypes.string,
  links: PropTypes.objectOf(PropTypes.array),
  customContent: PropTypes.func,
  newText: PropTypes.string,
  columnsConfig: PropTypes.objectOf(PropTypes.object),
};
