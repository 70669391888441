import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import R from 'ramda';
import Heading from '@cotyorg/ccx-atoms/Heading';
import OptionalSection from '@cotyorg/ccx-utils/js/components/OptionalSection';
import { HeroFullBlockQuarter } from '@cotyorg/ccx-organism-hero';
import { CardoCarousel } from '@cotyorg/ccx-organism-carousel';
import { trackCarouselClickEvent } from '@cotyorg/ccx-utils/js/tracking/adobe-dtm/events/carousel';
import Link from '@cotyorg/ccx-atoms/Link';
import StandardLayout from '../../templates/StandardLayout';
import HeroQuarterImageContent from '../../organisms/HeroQuarterImageContent';

const HomeComponent = ({ sectionHeadings }) => (
  <StandardLayout>
    <div className="homepage">
      <HeroQuarterImageContent customHeroesStoreDataKey="heroQuarterImageContent" />

      <div className="latest-arrivals text--center">
        <div className="grid-container">
          <div className="grid-x align-center">
            <div className="cell xsmall-16 medium-14">
              <Heading level={2} extraClass="homepage-section-heading">
                {R.path(['productCarouselSection', 'heading'], sectionHeadings)}
              </Heading>
            </div>

            <div className="cell xsmall-16 medium-14">
              <CardoCarousel
                doNotLazyLoad={false}
                cardoProps={{
                  cardoStoreDataKey: 'productCarouselSection',
                  isTitleUnderlined: false,
                  hasBackground: false,
                  isSmallTitle: true,
                  fullWidth: false,
                  headingLevel: 5,
                  showStarRating: false,
                  fixedHeight: true,
                  zoomOnHover: true,
                  alignCenter: true,
                }}
                carouselProps={{
                  carouselsStoreDataKey: 'productCarouselSection',
                  headingLevel: 5,
                  rightChevronIconSrc: '/ccx-files/assets/chevron-right.svg',
                  leftChevronIconSrc: '/ccx-files/assets/chevron-left.svg',
                  onIndexChange: ({ type, index, isForward }) =>
                    trackCarouselClickEvent({
                      page: 'home',
                      carousel: 'productCarouselSection',
                      type,
                      index,
                      isForward,
                    }),
                }}
                cardsPerPageMap={{
                  xsmall: 1,
                  small: 2,
                  medium: 4,
                  large: 4,
                  xlarge: 4,
                }}
              />

              <OptionalSection
                renderCondition={
                  !!R.path(
                    ['productCarouselSection', 'link', 'url'],
                    sectionHeadings
                  )
                }
                section={() => (
                  <div className="latest-arrivals__cta-section">
                    <Link
                      {...(R.path(
                        ['productCarouselSection', 'link', 'openInNewWindow'],
                        sectionHeadings
                      ) && { target: '_blank' })}
                      link={R.path(
                        ['productCarouselSection', 'link', 'url'],
                        sectionHeadings
                      )}
                      extraClass="button button--large button--primary latest-arrivals__button"
                    >
                      {R.path(
                        ['productCarouselSection', 'link', 'text'],
                        sectionHeadings
                      )}
                    </Link>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid-container">
        <div className="grid-x align-center">
          <div className="cell xsmall-16 medium-14">
            <Heading
              extraClass="text--center homepage-section-heading"
              level={2}
            >
              {R.path(['heroesSection', 'heading'], sectionHeadings)}
            </Heading>
          </div>

          <div className="cell xsmall-16 medium-14">
            <HeroFullBlockQuarter
              heroStoreDataKey="heroesSectionHero1"
              ctaType="button"
              isUnderlined={false}
            />
          </div>

          <div className="cell xsmall-16 medium-14">
            <Heading
              extraClass="text--center homepage-section-heading"
              level={2}
            >
              {R.path(['storySection', 'heading'], sectionHeadings)}
            </Heading>
          </div>

          <div className="cell xsmall-16 medium-14">
            <HeroFullBlockQuarter
              heroStoreDataKey="heroesSectionHero2"
              ctaType="button"
              isUnderlined={false}
            />
          </div>
        </div>
      </div>
    </div>
  </StandardLayout>
);

HomeComponent.propTypes = {
  sectionHeadings: PropTypes.objectOf(PropTypes.object),
  cardo: PropTypes.objectOf(PropTypes.object),
};

export default connect(({ content }) => ({
  sectionHeadings: R.prop('sectionHeadings', content),
}))(HomeComponent);
