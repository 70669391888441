import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import R from 'ramda';

const HeroQuarterImageContentContainer = ({
  childComponent: ChildComponent,
  ...props
}) => <ChildComponent {...props} />;

const mapStateToProps = ({ content }, { customHeroesStoreDataKey }) => ({
  ...R.path(['customHeroes', customHeroesStoreDataKey], content),
});

HeroQuarterImageContentContainer.propTypes = {
  childComponent: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(HeroQuarterImageContentContainer);
