import React from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OptionalSection from '@cotyorg/ccx-utils/js/components/OptionalSection';
import { Cookie } from '@cotyorg/ccx-organism-cookie';
import { HeaderTwoRow } from '@cotyorg/ccx-organism-header';
import { FooterTwoRow } from '@cotyorg/ccx-organism-footer';
import { SocialNewsletterHalfWidth } from '@cotyorg/ccx-organism-social-newsletter';
import Search from '@cotyorg/ccx-organism-search/components/Search';
import { Social } from '@cotyorg/ccx-molecules/Social';

const StandardLayout = ({
  cookieContent,
  search,
  isServedFromLegacySite,
  children,
  presentationLocale,
  isOneTrustEnabled,
  showNewsletter,
}) => (
  <>
    <HeaderTwoRow
      fixed
      logoSrc="/ccx-files/assets/logo.svg"
      chevronIconSrc="/ccx-files/assets/chevron-down.svg"
      leftChevronIconSrc="/ccx-files/assets/chevron-left.svg"
      rightChevronIconSrc="/ccx-files/assets/chevron-right-white.svg"
      closeIconSrc="/ccx-files/assets/cross-white.svg"
      hamburgerIconSrc="/ccx-files/assets/hamburger.svg"
      logoPosition="left"
      themeName="filled"
      navType="streamlined"
      searchIconSrc="/ccx-files/assets/search-white.svg"
      showSearch
      logoWidth={181}
      logoHeight={60}
    />
    <Search
      appElement="#app"
      searchIconSrc="/ccx-files/assets/search.svg"
      closeIconSrc="/ccx-files/assets/cross.svg"
      clearSearchIconSrc="/ccx-files/assets/clear.svg"
      searchURL={R.prop('url')(search)}
      locale={R.prop('locale')(search)}
      presentationLocale={presentationLocale}
      searchPath="/search/"
      searchFieldName="search"
      searchButtonHidden
      isServedFromLegacySite={isServedFromLegacySite}
    />

    {children}

    <div className="newsletter-section">
      <div className="grid-container">
        <div className="grid-x">
          <div className="cell xsmall-16">
            {showNewsletter ? (
              <SocialNewsletterHalfWidth
                headingLevel={4}
                socialNewsletterStoreDataKey="SocialNewsletterHalfWidth"
                socialIconSrc={{
                  facebook: '/ccx-files/assets/facebook.svg',
                  instagram: '/ccx-files/assets/instagram.svg',
                  pinterest: '/ccx-files/assets/pinterest.svg',
                  youtube: '/ccx-files/assets/youtube.svg',
                  twitter: '/ccx-files/assets/twitter.svg',
                  vk: '/ccx-files/assets/vk.svg',
                }}
              />
            ) : (
              <div className="social-section">
                <Social
                  rel="nopener"
                  socialIconSrc={{
                    facebook: '/ccx-files/assets/facebook.svg',
                    instagram: '/ccx-files/assets/instagram.svg',
                    pinterest: '/ccx-files/assets/pinterest.svg',
                    youtube: '/ccx-files/assets/youtube.svg',
                    twitter: '/ccx-files/assets/twitter.svg',
                    vk: '/ccx-files/assets/vk.svg',
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>

    <FooterTwoRow
      minusIconSrc="/ccx-files/assets/arrowup-small.svg"
      plusIconSrc="/ccx-files/assets/arrowdown-small.svg"
      countrySelector={{
        visible: true,
      }}
      navigationLinkIconWidth={16}
    />

    <OptionalSection
      renderCondition={!isOneTrustEnabled}
      section={() => (
        <Cookie
          position="bottom"
          content={cookieContent}
          closeIconSrc="/ccx-files/assets/cross-white.svg"
        />
      )}
    />
  </>
);

StandardLayout.propTypes = {
  cookieContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    dismissText: PropTypes.string,
  }).isRequired,
  search: PropTypes.shape({
    url: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    contentLabel: PropTypes.string,
    placeholderText: PropTypes.string,
    clearSearchTermHistoryButtonText: PropTypes.string,
    popularSearches: PropTypes.instanceOf(PropTypes.array),
  }),
  isServedFromLegacySite: PropTypes.bool,
  children: PropTypes.node,
  presentationLocale: PropTypes.string.isRequired,
  isOneTrustEnabled: PropTypes.bool,
  showNewsletter: PropTypes.bool.isRequired,
};

export default connect(
  ({ content, breakpoints, status, presentationLocale }) => ({
    cookieContent: R.prop('cookie')(content),
    search: R.prop('search')(content),
    currentBreakpoint: R.prop('current')(breakpoints),
    isServedFromLegacySite: R.prop('isServedFromLegacySite')(status),
    presentationLocale,
    isOneTrustEnabled: R.prop('isOneTrustEnabled')(content),
    showNewsletter: R.path(['newsletter', 'submitButtonText'], content),
  })
)(StandardLayout);
